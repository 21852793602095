import React from "react";
import lottieJson from "./../../assets/videos/noData.json";
import Lottie from "react-lottie-player";

export default function NoData({
	width = 300,
	height = 300,
	isShowText = true,
}) {
	return (
		<div>
			<Lottie
				play
				loop
				animationData={lottieJson}
				style={{ width: width, height: height, margin: "0 auto" }}
			/>
			{isShowText ? <b className="d-block text-center">No Data</b> : null}
		</div>
	);
}
