import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	user: localStorage.getItem("user")
		? JSON.parse(localStorage.getItem("user"))
		: null,
};

const authSlice = createSlice({
	name: "auth",
	initialState: initialState,
	reducers: {
		resetAuth: state => {
			localStorage.removeItem("user");
			localStorage.removeItem("token");
			localStorage.removeItem("refreshToken");
			localStorage.removeItem("publicKey");
			state.user = null;
		},
		setUser: (state, { payload }) => {
			state.user = payload;
		},
	},
});
export const { resetAuth, setUser } = authSlice.actions;
export default authSlice.reducer;
