import { combineReducers, configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./reducers/AuthReducer";

const rootReducer = combineReducers({
	auth: AuthReducer,
});

const localStorageMiddleware = ({ getState }) => {
	return next => action => {
		const result = next(action);
		const authState = getState().auth;
		if (authState && authState.hasOwnProperty("user")) {
			try {
				localStorage.setItem("user", JSON.stringify(authState.user));
			} catch (error) {
				console.error("Error saving auth state to local storage:", error);
			}
		}

		return result;
	};
};

const store = configureStore({
	reducer: rootReducer,
	// preloadedState: reHydrateStore(),
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware().concat(localStorageMiddleware),
});

export default store;
