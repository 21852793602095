import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAppSubmit } from "../../helpers/hooks";
import {
	Link,
	useActionData,
	useFormAction,
	useNavigate,
} from "react-router-dom";
import { Card, Form } from "react-bootstrap";
import CustomsInput from "../../components/Inputs/CustomsInput";
import ActionButton from "../../components/butons/ActionButton";
import { singUpValidation } from "../../helpers/validation";
import ApiService from "../../helpers/api";
import AlertService from "../../helpers/alertService";

function Component(props) {
	const navigate = useNavigate();
	const submit = useAppSubmit(),
		action = useFormAction();
	const actiondata = useActionData();
	const formMethods = useForm({
		defaultValues: {
			fullName: "",
			companyName: "",
			password: "",
			email: "",
		},
		resolver: yupResolver(singUpValidation),
	});
	const { handleSubmit } = formMethods;
	useEffect(() => {
		if (actiondata?.status === 200) {
			navigate("/");
		}
	}, [actiondata, navigate]);
	const onSubmit = data => {
		const formData = new FormData();
		Object.entries(data).forEach(([key, value]) => {
			if (Array.isArray(value)) {
				value.forEach((el, i) => {
					formData.append(key, el);
				});
			} else formData.append(key, value);
		});
		submit(formData, { method: "POST", action });
	};

	return (
		<div className="sign_container pb-4">
			<Card className="p-4 coustm_card d-flex gap-4 flex-column justify-content-center rounded">
				<h1 className="text-center fs-1">Sign up</h1>
				<FormProvider {...formMethods}>
					<Form
						noValidate={true}
						onSubmit={handleSubmit(onSubmit)}
						className="">
						<CustomsInput
							regName={"fullName"}
							type="text"
							required={true}
							label={"Full Name"}
							placeholder={"Full Name"}
						/>
						<CustomsInput
							regName={"companyName"}
							type="text"
							required={true}
							label={"Company Name"}
							placeholder={"Company name"}
						/>
						<CustomsInput
							regName={"email"}
							type="text"
							required={true}
							label={"Email"}
							placeholder={"Email"}
						/>
						<CustomsInput
							regName={"password"}
							type="password"
							required={true}
							label={"Password"}
							placeholder={"Password"}
						/>

						<ActionButton
							variant="outline-primary"
							buttonClassName="w-100 mt-4 "
							buttonName="Sign up"
						/>
					</Form>

					<div className="mb-0 text-center">
						<hr />
						If you have an account?{" "}
						<Link
							to="/"
							style={{
								color: "#0d6efd",
							}}
							className="fw-bold link">
							Sign in
						</Link>
					</div>
				</FormProvider>
			</Card>
		</div>
	);
}

const action = async ({ request, params }) => {
	try {
		const formData = await request.formData();
		const userData = Object.fromEntries(formData);
		const data = await ApiService.partner_create(userData);
		if (data.data) AlertService.alert("success", "Data seved");
		return data;
	} catch (error) {
		return error;
	}
};

const SignUp = Object.assign(Component, { action });

export default SignUp;
